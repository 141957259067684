<template>
  <v-container>
    <v-row no-gutters>
      <h2>{{ $t("pages.project_manager.title") }}</h2>
    </v-row>
    <v-row no-gutters>
      <v-btn
        text
        tile
        :to="{name: 'ProjectCreate'}"
      >
        <v-icon left>mdi-plus</v-icon> {{ $t('btn.project_create') }}
      </v-btn>
    </v-row>
    <v-row no-gutters>
      <v-simple-table dense fixed-header height="300px">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                {{ $t("pages.project_manager.table.headers.name") }}
              </th>
              <th class="text-left">
                {{ $t("pages.project_manager.table.headers.id") }}
              </th>
              <th class="text-left">
                {{ $t("pages.project_manager.table.headers.url") }}
              </th>
              <th class="text-left">
                {{ $t("pages.project_manager.table.headers.created_at") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in projects" :key="item.projectId">
              <td>{{ item.humanName }}</td>
              <td>
                <router-link
                  :to="{
                    name: 'ProjectOverview',
                    params: { projectId: item.projectId },
                  }"
                  >{{ item.projectId }}</router-link
                >
              </td>
              <td>
                <v-btn small text :href="item.projectUrl" target="_blank"
                  ><v-icon small left>mdi-open-in-new</v-icon
                  >{{ item.projectUrl }}</v-btn
                >
              </td>
              <td>{{ item.createdAt }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-row>
  </v-container>
</template>

<script>
import api from "../api";
import _ from "lodash";
export default {
  name: "ListProjects",
  created() {
    api.project.myProjects().then((res) => {
      this.projects = _.orderBy(
        res.listProjectsList,
        [
          function (o) {
            return o.createdAt;
          },
        ],
        ["desc"]
      );
    });
  },
  data: () => ({
    projects: [],
  }),
  components: {},
};
</script>
